import React from 'react'
import moment from 'moment'
import _get from 'lodash/get'
import Layout from '../../components/layout'
import Head from '../../components/head'
import { getEvent } from '../../http_service'
import NoDataImg from '../../assets/images/no_image.png'
import { TicketsContainer } from 'tf-checkout-react'
import { setTfCheckoutReactConfigs } from '../../utils/tf-checkout-config'
import './style.scss'
import Error from '../../components/error'

const isWindowDefined = typeof window !== 'undefined'

const handleCartSuccessRedirect = (
  skip_billing_page,
  names_required,
  phone_required,
  age_required,
  event_id = '',
  hash,
  total,
) => {
  const isWindowDefined = typeof window !== 'undefined'
  if (skip_billing_page) {
    isWindowDefined &&
      window.localStorage.setItem(
        'checkoutData',
        JSON.stringify({ hash, total }),
      )
    window.location.href = `/billing/checkout?event_id=${event_id}`
  } else {
    event_id && isWindowDefined
      ? window.location.assign(
          `/billing/billing-info?phone_required=${phone_required}&age_required=${age_required}&names_required=${names_required}&event_id=${event_id}`,
        )
      : isWindowDefined &&
        window.location.assign(
          `/billing/billing-info?phone_required=${phone_required}&age_required=${age_required}&names_required=${names_required}`,
        )
  }
}

const getQueryPromoCode = () => {
  if (typeof window !== 'undefined') {
    const params = new URL(window.location.href).searchParams
    const promoCode = params.get('r')
    return promoCode || ''
  }

  return ''
}

const createMarkup = (data) => {
  return { __html: data }
}

setTfCheckoutReactConfigs()

const EventPage = ({ serverData }) => {
  const event = _get(serverData, 'event', {})
  const venue = _get(serverData, 'venue', {})

  const {
    name,
    start_date,
    image_full_url,
    description,
    id,
    minimum_age,
    description_plain,
  } = event
  const { name: venueName, city, country } = venue
  const plainDescription = (description_plain || '').slice(0, 100)
  const metaTitle = `${name} | Manacommon`

  const date = moment(start_date).format('dddd, MMMM D, YYYY')
  const startTime = moment(start_date).format('LT').toLowerCase()
  const venueFullAddress = `${venueName} , ${city}`
  const dateInfo = `${date}, ${startTime}`
  const imgURL = image_full_url ? image_full_url : NoDataImg
  const minAgeValue = minimum_age ? `[Ages ${minimum_age} +]` : '[All Ages]'

  if (serverData.error) {
    console.log('serverData-slug', serverData)
    return <Error />
  }

  return (
    <>
      <Head
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: plainDescription,
          },
          {
            property: `og:description`,
            content: plainDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `twitter:title`,
            content: metaTitle,
          },
          {
            property: `twitter:description`,
            content: plainDescription,
          },
          {
            property: 'og:image',
            content: image_full_url,
          },
          {
            property: 'og:image:width',
            content: '400',
          },
          {
            property: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            property: 'twitter:label1',
            content: 'DATE',
          },
          {
            property: 'twitter:data1',
            content: date,
          },
          {
            property: 'twitter:label2',
            content: 'VENUE',
          },
          {
            property: 'twitter:data2',
            content: venueFullAddress,
          },
          {
            property: 'twitter:site',
            content: '@ManaCommon',
          },
          {
            property: 'twitter:creator',
            content: '@ManaCommon',
          },
          {
            property: 'twitter:domain',
            content: isWindowDefined ? window.location.host : '',
          },
          {
            property: 'og:url',
            content: isWindowDefined ? window.location.host : '',
          },
          {
            property: 'twitter:image:src',
            content: image_full_url,
          },
          {
            property: 'fb:app_id',
            content: '149831345062797',
          },
          {
            property: 'og:type',
            content: 'theticketfairy:event',
          },
        ]}
      />
      <Layout>
        <div
          className="event-details-container"
          itemScope
          itemType="https://schema.org/Event"
        >
          <div className="mobile-event-image-container">
            <img
              itemProp="image"
              src={imgURL}
              alt="No Data"
              onError={({ currentTarget }) => {
                currentTarget.setAttribute('style', 'display: none;')
              }}
            />
          </div>
          <div className="info-container">
            <p className="name" itemProp="name">
              {name}
            </p>
            <p className="date-venue">
              <span itemProp="startDate">{dateInfo}</span>
              <span>. </span>
              <span
                itemProp="location"
                itemScope
                itemType="https://schema.org/Place"
              >
                <span itemProp="name">{venueName}</span>
                <span
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <span itemProp="addressLocality">{city}</span>
                  {country ? (
                    <meta
                      itemRef="address-microdata"
                      itemProp="addressCountry"
                      content={country.toUpperCase()}
                    />
                  ) : null}
                </span>
              </span>
              <span>{minAgeValue}</span>
            </p>
            <div className="divider" />
          </div>
          <div className="main-content-container">
            <div className="mn__event-tickets-container">
              <TicketsContainer
                theme="light"
                eventId={id}
                onAddToCartSuccess={({
                  skip_billing_page,
                  names_required,
                  phone_required,
                  age_required,
                  event_id,
                  hash,
                  total,
                }) =>
                  handleCartSuccessRedirect(
                    skip_billing_page,
                    names_required,
                    phone_required,
                    age_required,
                    event_id,
                    hash,
                    total,
                  )
                }
                isPromotionsEnabled={event?.is_promotions_enabled}
                isAccessCodeEnabled={event?.is_access_code}
                onLogoutSuccess={() => {
                  if (typeof window !== 'undefined') {
                    window.location.href = '/'
                  }
                }}
                queryPromoCode={getQueryPromoCode()}
                themeOptions={{
                  input: {
                    fontFamily: 'Neue Haas Grotesk Display Pro',
                    letterSpacing: '0.2em',
                    fontWeight: '900',
                    fontSize: '18px',
                    lineHeight: '25px',
                  },
                }}
                hideSessionButtons={true}
                //onGetTicketsSuccess={() => {}}
              />
              <div className="event-image-container">
                <img
                  itemProp="image"
                  src={imgURL}
                  alt="No Data"
                  onError={({ currentTarget }) => {
                    currentTarget.setAttribute('style', 'display: none;')
                  }}
                />
              </div>
            </div>
            <div
              itemProp="description"
              className="description-container"
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          </div>
        </div>
      </Layout>
    </>
  )
}

export async function getServerData(props) {
  const {
    params: { slug },
    query: { pk },
  } = props

  try {
    const event = await getEvent(slug, pk)

    return {
      props: event,
    }
  } catch (error) {
    return {
      props: { error: true, message: error },
    }
  }
}

export default EventPage
